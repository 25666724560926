import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Fragment } from 'react';
import { Container, Text, Stack } from '@carvertical/ui';
import { sortBy } from 'lodash';
import { useRouteData } from 'context/RouteDataProvider';
import { NS_BUSINESS } from 'constants/i18n';
import ExternalLink from 'components/common/ExternalLink';
import {
  CUSTOM_PARTNER_LOGOS,
  INSURANCE_PARTNER_LOGOS,
  LEASING_PARTNER_LOGOS,
  PARTNER_LOGOS,
} from '../constants';
import type { BusinessPagePartialRouteData, LandingType } from '../types';
import styles from './TrustBar.module.scss';

type TrustBarProps = {
  type?: LandingType;
};

type GetLogosProps = {
  type: LandingType;
  marketId: string;
  partnerId?: string;
};

const PARTNERS_COUNT = 2200;

const LOGOS = {
  dealership: PARTNER_LOGOS,
  insurance: INSURANCE_PARTNER_LOGOS,
  leasing: LEASING_PARTNER_LOGOS,
  'custom-partner': CUSTOM_PARTNER_LOGOS,
};

const TrustBar = ({ type = 'dealership' }: TrustBarProps) => {
  const { t } = useTranslation(NS_BUSINESS);
  const {
    market: { id: marketId },
    partnerId,
  } = useRouteData<BusinessPagePartialRouteData>();

  const logos = getLogos({ type, marketId, partnerId });

  return (
    <section className={styles.root}>
      <Container>
        <Stack className={styles.wrapper} gap={1} crossAxisAlign="center">
          <Text as="h2" variant="s+" className={styles.title}>
            <Trans i18nKey="aboutUsSection.title" count={PARTNERS_COUNT} t={t}>
              {{ count: PARTNERS_COUNT }}+ Business Partners Worldwide
            </Trans>
          </Text>

          <Stack
            type="horizontal"
            className={styles.logos}
            crossAxisAlign="center"
            mainAxisAlign="spaceBetween"
            gap={8}
          >
            {logos.map((logo) => {
              const Wrapper = logo.link
                ? (props: { key: string }) => (
                    <ExternalLink className={styles.link} href={logo.link} {...props} />
                  )
                : Fragment;

              return (
                <Wrapper key={logo.alt}>
                  <Image className={styles.logo} {...logo} alt={logo.alt} />
                </Wrapper>
              );
            })}
          </Stack>
        </Stack>
      </Container>
    </section>
  );
};

function getLogos({ marketId, type, partnerId }: GetLogosProps) {
  const logos = LOGOS[type][marketId] || LOGOS[type].default;

  if (!partnerId) {
    return logos;
  }

  return sortBy(logos, ({ id }) => (id === partnerId ? 0 : 1));
}

export { TrustBar, PARTNERS_COUNT };
