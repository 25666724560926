import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Container, Grid, GridItem, Heading, Stack, Text } from '@carvertical/ui';
import type { ParseKeys } from 'i18next';
import { Section } from 'components/landings/common/Section';
import vytautasUrl from '../assets/images/team/vytautas.jpg';
import ievaUrl from '../assets/images/team/ieva.jpg';
import kotrynaUrl from '../assets/images/team/kotryna.jpg';
import robertasUrl from '../assets/images/team/robertas.jpg';
import karolinaUrl from '../assets/images/team/karolina.jpg';
import lauraUrl from '../assets/images/team/laura.jpg';
import danielUrl from '../assets/images/team/daniel.jpg';
import jauniusUrl from '../assets/images/team/jaunius.jpg';
import styles from './TeamSection.module.scss';

type Person = {
  imageUrl: string;
  name: string;
  roleKey: ParseKeys<'business'>;
  context?: 'male' | 'female';
};

const PEOPLE: Person[] = [
  {
    imageUrl: vytautasUrl,
    name: 'Vytautas Nemura',
    roleKey: 'teamSection.b2bHeadTitle',
    context: 'male',
  },
  {
    imageUrl: karolinaUrl,
    name: 'Karolina Žilinskė',
    roleKey: 'teamSection.salesRepresentativeFranceTitle',
    context: 'female',
  },
  {
    imageUrl: robertasUrl,
    name: 'Robertas Boravskis',
    roleKey: 'teamSection.marketingLeadTitle',
  },
  {
    imageUrl: lauraUrl,
    name: 'Laura Kazakaitė',
    roleKey: 'teamSection.salesRepresentativeItalyTitle',
    context: 'female',
  },
  {
    imageUrl: danielUrl,
    name: 'Daniel Artisiuk',
    roleKey: 'teamSection.salesRepresentativePolandTitle',
  },
  {
    imageUrl: jauniusUrl,
    name: 'Jaunius Kadunas',
    roleKey: 'teamSection.salesRepresentativeTitle',
  },
  {
    imageUrl: ievaUrl,
    name: 'Ieva Mejerytė',
    roleKey: 'teamSection.b2bCommunicationsTitle',
    context: 'female',
  },
  {
    imageUrl: kotrynaUrl,
    name: 'Kotryna Mažeikytė',
    roleKey: 'teamSection.salesRepresentativeFranceTitle',
    context: 'female',
  },
];

const TeamSection = () => {
  const { t } = useTranslation('business');

  return (
    <Section>
      <Container>
        <Grid columnGap={4} columnCount={{ mobileUp: 1, desktopUp: 12 }}>
          <GridItem
            span={{
              mobileUp: 1,
              desktopUp: 10,
            }}
            start={{
              mobileUp: 1,
              desktopUp: 2,
            }}
          >
            <Stack gap={{ mobileUp: 5, desktopUp: 8 }} crossAxisAlign="stretch">
              <Stack gap={2} crossAxisAlign="center">
                <Heading as="h2" variant="l" align="center">
                  {t('teamSection.title')}
                </Heading>

                <Text variant="l" className={styles.subtitle} align="center">
                  {t('teamSection.subtitle')}
                </Text>
              </Stack>

              <ul className={styles.list}>
                {PEOPLE.map(({ context, imageUrl, name, roleKey }) => (
                  <li key={name}>
                    <figure className={styles.figure}>
                      <Stack gap={2}>
                        <div className={styles.imageWrapper}>
                          <Image
                            src={imageUrl}
                            alt={name}
                            quality={100}
                            fill
                            className={styles.image}
                          />
                        </div>

                        <figcaption>
                          <Stack gap={0}>
                            <Text variant="m+">{name}</Text>

                            {roleKey && (
                              <Text variant="s" textColor="darkSecondary">
                                {t(roleKey, { context })}
                              </Text>
                            )}
                          </Stack>
                        </figcaption>
                      </Stack>
                    </figure>
                  </li>
                ))}
              </ul>
            </Stack>
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

export { TeamSection };
