import { useTranslation } from 'next-i18next';
import { Container, Grid, GridItem, Heading, Stack } from '@carvertical/ui';
import { useId, useState } from 'react';
import { AnimatePresence, type AnimationProps, motion } from 'framer-motion';
import { NS_BUSINESS } from 'constants/i18n';
import { Section } from 'components/landings/common/Section';
import { SupportBlock } from 'modules/support/components';
import { CONTACT_SECTION_ID } from '../../constants';
import type { MainActivity } from '../../types';
import { ContactForm } from './ContactForm';
import { SubmitConfirmation } from './SubmitConfirmation';
import styles from './ContactSection.module.scss';

type ContactSectionProps = {
  mainActivity: MainActivity;
};

const ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' },
  },
  transition: {
    duration: 0.5,
    ease: 'easeInOut',
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const ContactSection = ({ mainActivity }: ContactSectionProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { t } = useTranslation(NS_BUSINESS);
  const formWrapperId = useId();

  return (
    <Section id={CONTACT_SECTION_ID} color="colorGrey100">
      <Container>
        <AnimatePresence initial={false} mode="wait">
          {formSubmitted ? (
            <SubmitConfirmation onBack={() => setFormSubmitted(false)} />
          ) : (
            <motion.div {...ANIMATION_PROPS} key={formWrapperId}>
              <Stack gap={5} crossAxisAlign="stretch">
                <Grid
                  columnGap={4}
                  rowGap={3}
                  columnCount={{
                    mobileUp: 1,
                    tabletLandscapeUp: 12,
                  }}
                >
                  <GridItem span={{ mobileUp: 1, tabletLandscapeUp: 6 }}>
                    <Heading as="h2" variant="l" className={styles.heading}>
                      {t('contactSection.title')}
                    </Heading>
                  </GridItem>

                  <GridItem
                    span={{ mobileUp: 1, tabletLandscapeUp: 6 }}
                    className={styles.supportBlock}
                  >
                    <SupportBlock />
                  </GridItem>
                </Grid>

                <ContactForm mainActivity={mainActivity} onSubmit={() => setFormSubmitted(true)} />
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </Section>
  );
};

export { ContactSection, ANIMATION_PROPS };
