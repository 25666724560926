import Page from 'components/common/Page';
import { TestimonialsSection } from 'components/landings/common/TestimonialsSection';
import { BlogSection } from 'components/landings/common/BlogSection';
import { Header } from 'modules/shared';
import { MainActivity } from '../types';
import { useCampaign } from '../hooks';
import { BusinessPageHero } from './BusinessPageHero';
import { TeamSection } from './TeamSection';
import { BusinessFaqSection } from './BusinessFaqSection';
import { PricingSection } from './PricingSection';
import { TrustBar } from './TrustBar';
import { ContactSection } from './ContactSection';
import { BenefitsSection } from './BenefitsSection';
import { TrustSection } from './TrustSection';
import styles from './BusinessPage.module.scss';

const TYPE = 'business';

const BusinessPage = () => {
  const { campaignShown, extendedCampaignShown } = useCampaign();
  const fullPageShown = campaignShown ? extendedCampaignShown : true;

  return (
    <Page
      breadcrumbs={false}
      header={fullPageShown ? undefined : <Header logoOnly logoInteractive={false} />}
    >
      <BusinessPageHero type={TYPE} />

      <TrustBar />

      {fullPageShown && (
        <>
          <PricingSection preventMarketSuggestion={campaignShown} />

          <BusinessFaqSection className={styles.faqSection} />

          <BenefitsSection type={TYPE} />

          <TestimonialsSection type={TYPE} />

          <TrustSection />

          <TeamSection />

          <ContactSection mainActivity={MainActivity.CarDealer} />

          <BlogSection />
        </>
      )}
    </Page>
  );
};

export { BusinessPage };
