import Page from 'components/common/Page';
import { TestimonialsSection } from 'components/landings/common/TestimonialsSection';
import { BlogSection } from 'components/landings/common/BlogSection';
import { MainActivity } from '../types';
import { ContactSection } from './ContactSection';
import { TeamSection } from './TeamSection';
import { BusinessPageHero } from './BusinessPageHero';
import { BenefitsSection } from './BenefitsSection';
import { TrustSection } from './TrustSection';
import { BusinessFaqSection } from './BusinessFaqSection';
import { TrustBar } from './TrustBar';

const TYPE = 'leasing';

const LeasingPage = () => (
  <Page breadcrumbs={false} supportDisabled>
    <BusinessPageHero type={TYPE} />

    <TrustBar type="leasing" />

    <BenefitsSection type={TYPE} />

    <TestimonialsSection type={TYPE} />

    <TeamSection />

    <TrustSection />

    <ContactSection mainActivity={MainActivity.Leasing} />

    <BlogSection />

    <BusinessFaqSection />
  </Page>
);

export { LeasingPage };
