import cx from 'classnames';
import { isPlainObject, sortBy } from 'lodash';
import { useTranslation } from 'next-i18next';
import { Grid, Icon, Stack, Text } from '@carvertical/ui';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import { FEATURES as DEFAULT_FEATURES, BRITAIN_FEATURES, type Feature } from 'constants/features';
import styles from './FeatureCheckList.module.scss';

type FeatureCheckListProps = {
  features?: Feature[] | string[];
  variant?: 'black' | 'white';
  className?: string;
};

const FEATURE_ORDER = ['mileage', 'damage', 'photos', 'faults', 'owners', 'theft', 'other'];

const isFeatureObject = (features: Feature[] | string[]): features is Feature[] =>
  isPlainObject(features[0]);

const FeatureCheckList = ({
  className,
  features = DEFAULT_FEATURES,
  variant = 'black',
}: FeatureCheckListProps) => {
  const { market } = useRouteData();
  const { t } = useTranslation();

  const britainContentShown = isExistingMarket({
    markets: ['britain'],
    market,
  });

  const shownFeatures = (() => {
    let shown = [];

    if (isFeatureObject(features)) {
      const orderedFeatures = sortBy(features, (feature) => FEATURE_ORDER.indexOf(feature.id));

      shown = orderedFeatures.map(({ titleKey }) => titleKey);
    } else {
      shown = features;
    }

    if (britainContentShown) {
      shown = BRITAIN_FEATURES;
    }

    return shown;
  })();

  return (
    <Stack gap={2} className={className}>
      {britainContentShown && (
        <Text variant="m+" as="h2">
          {t('features.title')}
        </Text>
      )}

      <Grid
        as="ul"
        rowGap={{ mobileUp: 2, desktopUp: 3 }}
        columnGap={4}
        columnCount={2}
        className={cx('unstyledList', styles[variant])}
      >
        {shownFeatures.map((titleKey) => (
          <Stack as="li" key={titleKey} type="horizontal" gap={1} crossAxisAlign="center">
            <Icon name="check-drawn" size="s" />

            <Text textColor="inherited" variant="s">
              {/* @ts-expect-error TS(2345) */}
              {t(titleKey)}
            </Text>
          </Stack>
        ))}
      </Grid>
    </Stack>
  );
};

export { FeatureCheckList };
