import { Button, Container, Heading, Icon, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { Link } from 'components/common/Link';
import { SOLUTION_KEYS } from '../constants';
import IsoCertifiedIllustration from '../assets/images/iso-certified.svg';
import BusinessImage2 from '../assets/images/business-hero-image-2.jpg';
import InsuranceImage3 from '../assets/images/insurance-hero-image-3.jpg';
import { TickerItem, type TickerItemProps } from './TickerItem';

const TICKER_SLICE = 2;
const TICKER_ITEMS: TickerItemProps[] = [
  {
    descriptionKey: 'landing.isoDescription',
    title: <IsoCertifiedIllustration className="h-10 w-10" />,
    color: 'black',
    id: 'iso',
  },
  { image: BusinessImage2, id: 'businessImage2' },
  {
    descriptionKey: 'figuresSection.businessPartners',
    title: '2 200+',
    color: 'blue',
    id: 'businessPartners',
  },
  { image: InsuranceImage3, id: 'insuranceImage3' },
  {
    descriptionKey: 'figuresSection.usersPerMonth',
    title: '1 800 000+',
    color: 'blue',
    id: 'usersPerMonth',
  },
];

const BusinessPromoSection = () => {
  const { t } = useTranslation('business');
  const tickerStart = TICKER_ITEMS.slice(0, TICKER_SLICE);
  const tickerRest = TICKER_ITEMS.slice(TICKER_SLICE);

  return (
    <section className="bg-darkBlue py-8">
      <Container>
        <div className="grid grid-cols-1 gap-7 lg:grid-cols-[1fr_auto]">
          <div className="flex flex-col items-start justify-center gap-5">
            <div className="flex flex-col gap-2">
              <Heading as="h2" variant="l" textColor="light">
                {t('heroSection.title')}
              </Heading>

              <Text as="p" textColor="light">
                {t('heroSection.description')}
              </Text>
            </div>

            <ul className="m-0 flex list-none flex-col gap-2 p-0">
              {SOLUTION_KEYS.map((key) => (
                <li className="flex gap-1" key={key}>
                  <Icon className="text-white" name="check-bold" size="m" />
                  <Text key={key} variant="m+" textColor="light">
                    {t(key)}
                  </Text>
                </li>
              ))}
            </ul>

            <Button
              variant="yellow"
              as={Link}
              // @ts-expect-error TS(2322)
              to="/business"
            >
              {t('general.becomePartnerLabel')}
            </Button>
          </div>

          <div className="hidden lg:grid lg:grid-cols-2 lg:gap-3">
            <div className="my-auto flex flex-col gap-3">
              {tickerStart.map((item) => (
                <TickerItem className="shrink-0" key={item.id} {...item} />
              ))}
            </div>

            <div className="flex flex-col gap-3">
              {tickerRest.map((item) => (
                <TickerItem className="shrink-0" key={item.id} {...item} />
              ))}
            </div>
          </div>

          <div className="hideScrollbar flex snap-x snap-mandatory scroll-p-0 gap-2.5 overflow-x-auto scroll-smooth lg:hidden">
            {TICKER_ITEMS.map((item) => (
              <TickerItem className="shrink-0 snap-start" key={item.id} {...item} />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export { BusinessPromoSection };
